<template>
  <qriously :size="size" :value="downloadLink" />
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      link: "https://support.weex.today/register"
    };
  },
  props: {
    size: {
      type: Number,
      default: 150
    },
    lang: {
      type: String,
      default: "zh-CN"
    },
    shareUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    downloadLink() {
      if(this.shareUrl !== ''){
        return this.shareUrl;
      } else {
        let link = this.link;
        let languageType = "1";
        switch (this.lang) {
          case "en":
            languageType = "0";
            break;
          case "ja":
            languageType = "2";
            break;
          case "ko":
            languageType = "3";
            break;
          case "vi":
            languageType = "4";
            break;
          case "zh-TW":
            languageType = "5";
        }
        return `${link}?languageType=${languageType}`;
      }
    }
  },
  methods: {
    async getQrcodeDomain() {
      let  domain = "https://dswcnhr3lpzog.cloudfront.net/icp.txt";
      let res1 = await axios.get(domain).then((res) => res.data);
      this.link = this.AES_CBC_DECRYPT(
        res1,
        "MwxmPJJHmXasfr45",
        "yRKXEEPaEbHAbCPy"
      );
      this.link = "https://" + this.link + "/" + this.lang + "/jumpdl";
      //console.log("link:",this.link)
    }
  },
  mounted(){
    //this.getQrcodeDomain(); //暫時取消使用微信專用域名
    let href = '';
      let Host = location.host;
      let locationHref = location.href;
      //locationHref = "https://wx26mb.info/zh-CN/"
      //Host = 'wx26mb.info'
      //locationHref = "https://www.weikestg.info/en/"
      //Host = 'weikestg.info'
      //locationHref = "https://stg-www.weex.tech/en/"
      //Host = 'stg-www.weex.tech'
      if(locationHref.indexOf('test')!=-1 || locationHref.indexOf('localhost')!=-1) { //Dev,Test
        href = "https://test-support.weex.tech/" + this.lang + "/jumpdl";
      } else if (locationHref.indexOf('stg-www')!=-1) {
        href = "https://stg-support.weex.tech/" + this.lang + "/jumpdl";
      } else { //PRD
        let domainArr = Host.split(".");
        let domainStr = domainArr[domainArr.length-2].concat('.').concat(domainArr[domainArr.length-1])
        href = "https://support." + domainStr + "/" + this.lang + "/jumpdl";
      }
      this.link = href;
  }
};
</script>
